import React, { Component, Fragment } from 'react';
import { Divider, Grid, Menu, Input, Icon, Segment, Header, Loader, Dropdown, Pagination, Button, Table, Modal, Label, GridColumn, List, ListItem, Popup, HeaderContent } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Configuration from '../config/Configuration.js';
import AuthenticationService from '../api/AuthenticationService.js';
import ServiceUtils from '../utils/ServiceUtils.js';
import { authHeader, authHeaderSet, anonHeader } from '../helpers/auth-header.js';
import { withTranslation } from 'react-i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import markerIcon from '../images/decorator-circle-orange.svg';
import { createMedia } from "@artsy/fresnel";
import {
    setKey,
    fromAddress
} from "react-geocode";

// Set the API key for Geocode
setKey('AIzaSyAqE43ZJgJp2moxHZmaWq49YSuaONCau0s');


const AppMedia = createMedia({
    breakpoints: {
        mobile: 320,
        tablet: 768,
        computer: 992,
        largeScreen: 1200,
        widescreen: 1920
    }
});

const { Media, MediaContextProvider } = AppMedia;

class Services extends Component {

    BXL_SOCIAL = "bxlSocial";
    SOCIALE_KAART = "socialeKaart";

    constructor() {
        super();
        this.config = new Configuration();
        this.authenticationService = new AuthenticationService();
        this.state = {
            activeUser: this.authenticationService.getActiveUser(),
            services: [],
            codableConcepts: [],
            codableConceptsPG: [],
            serviceName: '',
            codableConcept: '',
            activePage: 1,
            serviceRequestPageSize: 16,
            totalPages: 1,
            totalElements: 0,
            elzId: '',
            elzName: '',
            searchLocationId: '',
            searchLocationName: '',
            offerType: 'Eerstelijnszone',
            addService: false,
            serviceRetrievalOngoing: false,
            compactView: false,
            showHealthcareServiceModal: false,
            markers: []
        }
    }

    retrieveELZForLocation = (locationId) => {
        return fetch(this.config.LOCATIONS_URL + "?childId=" + locationId + "&level=Eerstelijnszone", {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.setState({
                    searchLocationId: location.uuid,
                    searchLocationName: location.name,
                    elzId: location.uuid,
                    elzName: location.name
                });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveLocationForPostalCode = (postalCode) => {
        return fetch(this.config.LOCATIONS_URL + "?code=" + postalCode, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(locations => {
                let locationz = locations._embedded.locations;
                let location = locationz[0];
                this.retrieveELZForLocation(location.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveAddress = (addressUuid) => {
        return fetch(this.config.ADDRESSES_URL + "/" + addressUuid, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(address => {
                this.retrieveLocationForPostalCode(address.postalCode);
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServiceAddress = (addressUuid) => {
        if (addressUuid) {
            let uuid = addressUuid.uuid;
            return fetch(this.config.ADDRESSES_URL + "/" + uuid, {
                method: 'GET',
                headers: authHeader()
            })
                .then(response => {
                    if (!response.ok) {
                        this.handleResponseError(response);
                    }
                    return response.json();
                })
                .then(address => {
                    return address;
                })
                .catch(error => {
                    this.handleError(error);
                });
        }
    }

    retrieveOrganization() {
        return fetch(this.config.ORGANIZATIONS_URL + "/" + this.state.organizationId, {
            method: 'GET',
            headers: authHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(organization => {
                this.retrieveAddress(organization.address.uuid);
            })
            .catch(error => {
                this.handleError(error);
            });
    }


    loadCodableConcepts() {
        let codableConcepts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=200&system="
            + this.config.CODABLE_CONCEPT_SYSTEM, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    codableConcepts.push(concept);
                }
                this.setState({ codableConcepts: codableConcepts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    loadCodableConceptsPG() {
        let codableConcepts = [];
        return fetch(this.config.CODABLECONCEPTS_URL + "?pagesize=10&system="
            + this.config.CODABLE_CONCEPT_PG_SYSTEM, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(concepts => {
                let conceptz = concepts._embedded.codableConcepts;
                for (var i = 0; i < conceptz.length; i++) {
                    let concept = conceptz[i];
                    codableConcepts.push(concept);
                }
                this.setState({ codableConceptsPG: codableConcepts });
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsBxlSocial = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetailsSocialeKaart = (serviceId) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getServiceDetails = (reference) => {
        let bxlSocialId = ServiceUtils.getBxlSocialReference(reference);
        if (bxlSocialId) {
            let serviceDetails = this.getServiceDetailsBxlSocial(bxlSocialId);
            return serviceDetails;
        } else {
            let serviceDetails = this.getServiceDetailsSocialeKaart(reference);
            return serviceDetails;
        }
    }

    getBxlSocialDetails = (serviceId) => {
        return fetch(this.config.BXL_SOCIAL_URL + "/" + serviceId, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(serviceDetails => {
                serviceDetails.serviceDetailsSource = this.BXL_SOCIAL;
                let healthcareService = this.state.healthcareService;
                healthcareService.serviceDetails = serviceDetails;
                this.setState({
                    healthcareService: healthcareService
                })
                return serviceDetails;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getSocialeKaartLeaflet = (healthcareService) => {
        return fetch(this.config.SOCIALE_KAART_LEAFLET_URL + "/" + healthcareService.socialeKaartReference, {
            method: 'GET',
            headers: anonHeader()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(leaflet => {
                leaflet.serviceDetailsSource = this.SOCIALE_KAART;
                healthcareService.serviceDetails = leaflet;
                this.setState({
                    healthcareService: healthcareService
                })
                return leaflet;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getMailableResource = (healthcareService) => {
        return fetch(this.config.MAILABLE_RESOURCES_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(mailableResources => {
                let resources = mailableResources._embedded.mailableResources;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    getHealthcareServiceAreas = (healthcareService) => {
        return fetch(this.config.HEALTHCARESERVICEAREAS_URL + "?healthcareService=" + healthcareService.uuid, {
            method: 'GET',
            headers: authHeaderSet()
        })
            .then(response => {
                if (!response.ok) {
                    this.handleResponseError(response);
                }
                return response.json();
            })
            .then(healthcareServiceAreas => {
                let resources = healthcareServiceAreas._embedded.healthcareServiceAreas;
                return resources;
            })
            .catch(error => {
                this.handleError(error);
            });
    }

    retrieveServices = async () => {
        try {
            let params = new URLSearchParams({
                pagesize: this.state.serviceRequestPageSize,
                page: this.state.activePage
            });
            if (this.state.serviceName && this.state.serviceName.length > 1) {
                params.append('name', this.state.serviceName);
            }
            if (this.state.codableConcept) {
                params.append('codableConcept', this.state.codableConcept);
            }
            if (this.state.searchLocationId && this.state.searchLocationId !== this.config.REGION_VLAANDEREN_ID) {
                params.append('location', this.state.searchLocationId);
            }

            const response = await fetch(`${this.config.HEALTHCARESERVICES_URL}?${params.toString()}`, {
                method: 'GET',
                headers: authHeaderSet()
            });

            if (!response.ok) {
                this.handleResponseError(response);
                return;
            }

            const services = await response.json();
            const { totalPages, totalElements } = services._page;
            const { _embedded } = services;

            if (_embedded.serviceRequests && _embedded.serviceRequests.length === 0) {
                this.setState({ noResults: true });
            }

            this.setState({
                totalPages,
                totalElements,
                serviceRetrievalOngoing: true,
                services: []
            });

            const fetchedServices = _embedded.healthcareServices;

            if (!this.state.compactView) {
                const updatedServices = await Promise.all(fetchedServices.map(async (service) => {
                    service.mailableResources = await this.getMailableResource(service) || [];

                    if (service.address) {
                        service.address = await this.retrieveServiceAddress(service.address) || service.address;
                    }

                    if (service.socialeKaartReference) {
                        service.serviceDetails = await this.getServiceDetails(service.socialeKaartReference) || {};
                    }

                    return service;
                }));

                this.setState({ services: updatedServices }, (() => this.fetchMarkers()));
            } else {
                this.setState({ services: fetchedServices }, (() => this.fetchMarkers()));
            }

            return fetchedServices;
        } catch (error) {
            this.handleError(error);
        }
    }

    componentDidUpdate() {
        if (this.state.searchLocationId && this.state.services.length === 0 && !this.state.serviceRetrievalOngoing) {
            this.retrieveServices();
        }
    }

    componentDidMount() {
        if (this.state.codableConcepts.length === 0) {
            this.loadCodableConcepts();
            this.loadCodableConceptsPG();
        }
        this.setState({
            organizationId: this.state.activeUser.organization.uuid
        },
            () => {
                this.retrieveOrganization();
            });
    }

    handleChange = (e, { name, value }) => { this.setState({ [name]: value }, () => { this.retrieveServices() }) }

    handleRegionChange = (e, { value }) => this.setState({ value })

    handleDropdownChange = (e, { value }) => { this.setState({ codableConcept: value }, () => this.retrieveServices()) }

    handleResponseError(response) {
        return {
            error: true,
            status: response.status
        };
    }

    handleError(error) {
        console.log(error.message);
    }

    fetchMarkers = async () => {
        if (this.state.services.length === 0) {
            console.log('Services array is empty');
            //return;
        }

        const newMarkers = [];
        for (const service of this.state.services) {
            if (service.address && service.address.lines) {
                const { lines, postalCode, city } = service.address;
                const addressLine = `${lines} ${postalCode} ${city}`;
                try {
                    const response = await fromAddress(addressLine);
                    if (response.results.length > 0) {
                        const { lat, lng } = response.results[0].geometry.location;
                        newMarkers.push({ lat, lng, service });
                    } else {
                        console.error('No results found for address:', addressLine);
                    }
                } catch (error) {
                    console.error('Error getting location for address:', addressLine, error);
                }
            } else {
                newMarkers.push({});
            }
        }
        this.setState({ markers: newMarkers });
    }

    indexToChar = (index) => {
        // Convert index to character (A-Z)
        return String.fromCharCode(65 + index);
    }

    showServiceType = (serviceType) => {
        if (serviceType) {
            return <Label size='mini'>{serviceType}</Label>;
        }
    }

    ////// SERVICE RENDERING ELEMENTS //////

    // ZIPSTER AS SOURCE //

    showZipsterServiceForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showZipsterService(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showZipsterService(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    // SOCIALE KAART OR BXL SOCIAL AS SOURCE //

    showExternalServiceInfoForMode = (healthcareService) => {
        if (this.state.showHealthcareServiceModal) {
            return ServiceUtils.showExternalServiceInfo(this.state.healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        } else {
            return ServiceUtils.showExternalServiceInfo(healthcareService, this.state.codableConcepts, this.state.compactView, this.props);
        }
    }

    getServiceBody = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            return this.showExternalServiceInfoForMode(healthcareService);
        } else {
            return this.showZipsterServiceForMode(healthcareService);
        }
    }

    // MODAL OPERATIONS //

    handleOpenHealthcareServiceModal = (healthcareService) => {
        if (healthcareService.socialeKaartReference) {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            }, () => {
                let bxlSocialId = ServiceUtils.getBxlSocialReference(healthcareService.socialeKaartReference);
                if (bxlSocialId) {
                    this.getBxlSocialDetails(bxlSocialId);
                } else {
                    this.getSocialeKaartLeaflet(healthcareService);
                }
            })
        } else {
            this.setState({
                showHealthcareServiceModal: true,
                healthcareService: healthcareService
            });
        }
    }

    handleCloseHealthcareServiceModal = () => {
        this.setState({ showHealthcareServiceModal: false });
    }

    getHealthcareServiceModal = () => {
        const { t } = this.props;
        if (this.state.healthcareService) {
            return <Modal
                open={this.state.showHealthcareServiceModal}
                onClose={this.handleCloseHealthcareServiceModal}>
                <Modal.Header>
                    {ServiceUtils.getSocialeKaartLabel(this.state.healthcareService, this.state.showHealthcareServiceModal, this.props)}
                    {this.state.healthcareService.name}
                </Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        {this.getServiceBody(this.state.healthcareService)}
                        {ServiceUtils.getSocialeKaartButton(this.state.healthcareService)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='blue' onClick={this.handleCloseHealthcareServiceModal}>
                        <Icon name='close' />{t("Close Window")}
                    </Button>
                </Modal.Actions>
            </Modal>
        }
    }

    getHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>{healthcareService.name}</Header>;
    }

    getMobileHealthcareServiceHeader = (healthcareService) => {
        return <Header as={Link} size='small' color='blue' onClick={this.handleOpenHealthcareServiceModal.bind(this, healthcareService)}>
            <Icon name='angle right' color='orange' />
            <HeaderContent>{healthcareService.name}</HeaderContent>
        </Header>;
    }

    getCompactServiceOverview = () => {
        const { t } = this.props;
        return <Fragment>
            {this.getHealthcareServiceModal()}
            <Table basic>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t("Service")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Support Needs")}</Table.HeaderCell>
                        <Table.HeaderCell>{t("Outreaching")}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                    <Table.Row>
                        <Table.Cell>
                            {this.getHealthcareServiceHeader(service)}
                        </Table.Cell>
                        <Table.Cell>
                            {ServiceUtils.getCodableConcepts(service, this.state.codableConcepts, this.state.compactView, this.props)}
                        </Table.Cell>
                        <Table.Cell>
                            {ServiceUtils.getReferralMethodIcon(service.referralMethod, this.props)}
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table>
        </Fragment>;
    }

    getExtendedServiceOverview = () => {
        return <MediaContextProvider>
            {/* Mobile Layout */}
            <Media at="mobile">
                {this.getHealthcareServiceModal()}
                <Grid columns={1} padded stackable>
                    {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                        <Grid.Column width={16} key={index}>
                            {this.getMobileHealthcareServiceHeader(service)}
                        </Grid.Column>
                    )}
                </Grid>
            </Media>
            {/* Other Layout */}
            <Media greaterThan="mobile">
                <Grid columns={1} padded stackable>
                    {this.state.services.slice(0, this.state.services.length).map((service, index) =>
                        <Grid.Column width={16} key={index}>
                            <Header size='large' attached='top' inverted style={{ backgroundColor: '#385773' }}>
                                {ServiceUtils.getSocialeKaartLabel(service, this.state.showHealthcareServiceModal, this.props)}<Header.Content>{service.name}</Header.Content></Header>
                            <Segment raised attached>
                                {this.getServiceBody(service)}
                            </Segment>
                        </Grid.Column>
                    )}
                </Grid>
            </Media>
        </MediaContextProvider>;
    }

    getReferralIcon = (service) => {
        if (service.referralMethod === 'email') {
            return <Icon color='blue' name='mail forward' />;
        } else {
            return <Icon color='blue' name='address card' />;
        }
    }

    getListItem = (service, index) => {
        if (service.type) {
            return <Popup basic
                position='left center'
                content={service.type}
                size='small'
                trigger={<ListItem key={index} onClick={this.handleOpenHealthcareServiceModal.bind(this, service)}><Label circular color={'orange'} key={index}>
                    {this.indexToChar(index)}
                </Label> <Label > {this.getReferralIcon(service)} {service.name}</Label>
                </ListItem>} />;
        } else {
            return <ListItem key={index} onClick={this.handleOpenHealthcareServiceModal.bind(this, service)}><Label circular color={'orange'} key={index}>
                {this.indexToChar(index)}
            </Label> <Label>{this.getReferralIcon(service)} {service.name} </Label>
            </ListItem>;
        }
    }

    getList = () => {
        const { totalPages } = this.state;
        if (this.state.services && this.state.services.length > 0) {
            return <Fragment>
                <List selection animated>
                    {this.state.services.slice(0, this.state.services.length).map((service, index) => { return this.getListItem(service, index) }
                    )}
                </List>
                <Segment basic textAlign='center'>
                    <Pagination
                        activePage={this.state.activePage}
                        onPageChange={this.handlePaginationChange}
                        size='mini'
                        totalPages={totalPages} textAlign='center' />
                </Segment>
            </Fragment>;
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }

    getServicesMap = () => {
        const { markers } = this.state;

        const mapStyles = {
            height: '800px',
            width: '100%',
        };

        const defaultCenter = {
            lat: 51.17743190199286, // Default center: Valaar
            lng: 4.382273411267179
        };

        return (
            <Fragment>
                {this.getHealthcareServiceModal()}
                <Grid columns={16}>
                    <GridColumn width={10}>
                        <Segment basic>
                            <LoadScript googleMapsApiKey='AIzaSyAqE43ZJgJp2moxHZmaWq49YSuaONCau0s'>
                                <GoogleMap
                                    mapContainerStyle={mapStyles}
                                    zoom={15}
                                    center={defaultCenter}
                                    mapTypeId='roadmap'>
                                    {markers.map((marker, index) => (
                                        <Marker key={index}
                                            position={marker}
                                            label={this.indexToChar(index)}
                                            icon={markerIcon}
                                            onClick={this.handleOpenHealthcareServiceModal.bind(this, marker.service)} />
                                    ))}
                                </GoogleMap>
                            </LoadScript>
                        </Segment>
                    </GridColumn>
                    <GridColumn width={6}>
                        <Segment basic>
                            {this.getList()}
                        </Segment>
                    </GridColumn>
                </Grid>
            </Fragment>
        );
    }

    getServices = () => {
        if (this.state.services) {
            if (this.state.compactView) {
                return this.getCompactServiceOverview();
            } else if (this.state.mapView) {
                return this.getServicesMap();
            } else {
                return this.getExtendedServiceOverview();
            }
        } else {
            return <Loader active inline='centered' size='large' />;
        }
    }

    getOptions = () => {
        const { t } = this.props;
        let options = [];
        for (var i = 0; i < this.state.codableConcepts.length; i++) {
            let codableConcept = this.state.codableConcepts[i];
            let option = { key: i, text: t(codableConcept.text), value: codableConcept.uuid };
            options.push(option);
        }
        return options;
    }


    handlePaginationChange = (e, { activePage }) => {
        this.setState({
            services: [],
            activePage: activePage
        }, () => {
            this.retrieveServices();
        })
    }

    toggleOffer = () => {
        if (this.state.offerType === 'Eerstelijnszone') {
            this.setState({
                serviceRetrievalOngoing: false,
                services: [],
                offerType: 'Gewest',
                searchLocationId: this.config.REGION_VLAANDEREN_ID,
                searchLocationName: 'Vlaanderen'
            });
        } else if (this.state.offerType === 'Gewest') {
            this.setState({
                serviceRetrievalOngoing: false,
                services: [],
                offerType: 'Eerstelijnszone',
                searchLocationId: this.state.elzId,
                searchLocationName: this.state.elzName
            });
        }
    }

    getRegionButtonDescription = () => {
        const { t } = this.props;
        if (this.state.offerType === 'Eerstelijnszone') {
            return t("Show Full Offer");
        } else if (this.state.offerType === 'Gewest') {
            return t("Show ELZ Offer");
        }
    }

    enableAddService = () => {
        this.setState({ addService: true })
    }

    switchViewingMode = () => {
        if (this.state.compactView) {
            this.setState({
                compactView: false,
                mapView: false,
                serviceRequestPageSize: 16,
                services: [],
                activePage: 1
            }, () => {
                this.retrieveServices();
            })
        } else {
            this.setState({
                compactView: true,
                mapView: false,
                serviceRequestPageSize: 60,
                services: [],
                activePage: 1
            }, () => {
                this.retrieveServices();
            })
        }
    }

    switchToMapMode = () => {
        this.setState({
            compactView: false,
            serviceRequestPageSize: 16,
            activePage: 1
        }, () => {
            let currentComponent = this;
            currentComponent.fetchMarkers();
            currentComponent.setState({
                mapView: true
            })
        })
    }

    getViewButton = () => {
        if (this.state.compactView) {
            return <Button icon floated='left' circular color='blue' onClick={this.switchViewingMode}><Icon name='list layout' /></Button>;
        } else {
            return <Button icon floated='left' circular color='blue' onClick={this.switchViewingMode}><Icon name='grid layout' /></Button>;
        }
    }

    getMapButton = () => {
        if (!this.state.mapView) {
            return <Button icon floated='left' circular color='orange' onClick={this.switchToMapMode}><Icon name='map outline' /></Button>;
        }
    }

    getServicesPG = (ax) => {
        const { codableConceptsPG } = this.state;
        if (ax) {
            for (var i = 0; i < codableConceptsPG.length; i++) {
                let concept = codableConceptsPG[i];
                if (concept.text === ax) {
                    this.setState({ codableConcept: concept.uuid }, () => this.retrieveServices());
                    return;
                }
            }
        }
    }

    getPaginationAndButton = () => {
        const { totalPages } = this.state;
        if (!this.state.mapView) {
            return <MediaContextProvider>
                {/* Mobile Layout */}
                <Media at="mobile"><Fragment>
                    <Pagination
                        activePage={this.state.activePage}
                        onPageChange={this.handlePaginationChange}
                        size='mini'
                        totalPages={totalPages}
                        siblingRange={1}
                        ellipsisItem={null}
                        boundaryRange={0}
                        firstItem={null}
                        lastItem={null} />
                </Fragment>
                </Media>
                <Media greaterThan="mobile"><Fragment>
                    <Pagination
                        activePage={this.state.activePage}
                        onPageChange={this.handlePaginationChange}
                        size='mini'
                        totalPages={totalPages} />
                    <Button icon color='blue' floated='right' labelPosition='right' onClick={this.toggleOffer} as={Link}>{this.getRegionButtonDescription()} <Icon name='marker' /></Button>
                </Fragment>
                </Media>
            </MediaContextProvider>;
        } else {
            return <Segment basic>
                <Button size='small' color='red' onClick={this.getServicesPG.bind(this, 'Lichaamsfuncties')}>Lichaamsfuncties</Button>
                <Button size='small' color='violet' onClick={this.getServicesPG.bind(this, 'Mentaal Welbevinden')}>Mentaal Welbevinden</Button>
                <Button size='small' color='purple' onClick={this.getServicesPG.bind(this, 'Zingeving')}>Zingeving</Button>
                <Button size='small' color='orange' onClick={this.getServicesPG.bind(this, 'Kwaliteit van Leven')}>Kwaliteit van Leven</Button>
                <Button size='small' color='brown' onClick={this.getServicesPG.bind(this, 'Meedoen')}>Meedoen</Button>
                <Button size='small' color='green' onClick={this.getServicesPG.bind(this, 'Dagelijks Functioneren')}>Dagelijks Functioneren</Button>
            </Segment>;
        }
    }

    getNumberOfResults = () => {
        const { t } = this.props;
        if (this.state.totalElements) {
            return <Label color='blue' inverted>{this.state.totalElements} {t("Results")}</Label>;
        }
    }

    showServices = () => {
        const { t } = this.props;
        const { serviceName, codableConcept } = this.state;
        return (<MediaContextProvider>
            {/* Mobile Layout */}
            <Media at="mobile">
                <Fragment>
                    <Header attached='top' size='huge' textAlign='center'>{t("Services")}<Header.Subheader>{this.state.searchLocationName}</Header.Subheader></Header>
                    <Segment attached>
                        <Menu text stackable>
                            <Menu.Item>
                            </Menu.Item>
                            <Menu.Item>
                                <Input name='serviceName' value={serviceName} onChange={this.handleChange} icon='search' placeholder={t("Search By Name")} />
                            </Menu.Item>
                            <Menu.Item>
                            </Menu.Item>
                            <Menu.Item>
                                <Dropdown
                                    placeholder={t("Filter By Need")}
                                    selection
                                    search
                                    options={this.getOptions()}
                                    fluid
                                    clearable
                                    value={codableConcept}
                                    onChange={this.handleDropdownChange}
                                />
                            </Menu.Item>
                        </Menu>
                        <Divider />
                        <Segment textAlign='center' basic>
                            {this.getPaginationAndButton()}
                            <Divider hidden />
                            {this.getNumberOfResults()}
                        </Segment>
                        {this.getServices()}
                    </Segment>
                </Fragment >
            </Media>
            {/* Other Layout */}
            <Media greaterThan="mobile">
                <Fragment>
                    <Header attached='top' size='huge' textAlign='center'>{t("Services")}<Header.Subheader>{this.state.searchLocationName}</Header.Subheader></Header>
                    <Segment attached>
                        <Menu text >
                            <Menu.Item>
                            </Menu.Item>
                            <Menu.Item>
                                <Input name='serviceName' value={serviceName} onChange={this.handleChange} icon='search' placeholder={t("Search By Name")} />
                            </Menu.Item>
                            <Menu.Item>
                            </Menu.Item>
                            <Menu.Menu text position='right'>
                                <Menu.Item>
                                    {this.state.totalElements} {t("Results")}
                                </Menu.Item>
                                <Menu.Item>
                                    {t("Filter By Need")}
                                </Menu.Item>
                                <Dropdown
                                    placeholder={t("Choose Support Need")}
                                    selection
                                    search
                                    options={this.getOptions()}
                                    fluid
                                    clearable
                                    value={codableConcept}
                                    onChange={this.handleDropdownChange}
                                />
                            </Menu.Menu>
                        </Menu>
                        <Divider />
                        <Segment textAlign='center' basic>
                            {this.getViewButton()}
                            {this.getPaginationAndButton()}
                        </Segment>
                        {this.getServices()}
                    </Segment>
                </Fragment >
            </Media>
        </MediaContextProvider>
        );
    }

    render() {
        return this.showServices();
    }

}

export default withTranslation()(Services);
